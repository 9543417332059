var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full max-h-screen bg-background flex flex-col relative"},[_c('page-header',{attrs:{"title":"Contacts"}},[(_vm.$getExperimentStatus('create-contact'))?_c('button',{staticClass:"flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1",on:{"click":function($event){_vm.showingCreateContactPanel = true}}},[_c('svg',{staticClass:"stroke-current h-5 w-5 mr-2",attrs:{"fill":"none","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M12 5v14M5 12h14"}})]),_c('span',[_vm._v("Create Contact")])]):_vm._e()]),_c('div',{staticClass:"p-6 bg-background flex-1 overflow-y-scroll"},[_c('div',{staticClass:"w-full bg-white p-5 rounded shadow-md relative"},[_c('div',{staticClass:"w-full flex items-center justify-between"},[_c('div',{staticClass:"flex space-x-2"},[_c('button',{staticClass:"px-4 py-2 rounded transition-colors duration-300 flex items-center font-semibold",class:{
              'bg-orange-300 text-orange-900': _vm.currentTab == 'all',
              'hover:bg-gray-200': _vm.currentTab != 'all',
            },on:{"click":function($event){return _vm.selectTab('all')}}},[_c('svg',{staticClass:"stroke-current h-5 w-5 mr-2",attrs:{"fill":"none","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M8 6h13M8 12h13M8 18h13M3 6h.01M3 12h.01M3 18h.01"}})]),_c('span',[_vm._v("All Contacts")])]),_c('button',{staticClass:"px-4 py-2 rounded transition-colors duration-300 flex items-center font-semibold",class:{
              'bg-orange-300 text-orange-900': _vm.currentTab == 'search',
              'hover:bg-gray-200': _vm.currentTab != 'search',
            },on:{"click":function($event){_vm.currentTab = 'search'}}},[_c('svg',{staticClass:"stroke-current h-5 w-5 mr-2",attrs:{"fill":"none","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round","viewBox":"0 0 24 24"}},[_c('circle',{attrs:{"cx":"11","cy":"11","r":"8"}}),_c('path',{attrs:{"d":"M21 21l-4.35-4.35"}})]),_c('span',[_vm._v("Search")])])]),_c('div',{staticClass:"flex flex-1 justify-end"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchTerm),expression:"searchTerm"}],staticClass:"ml-10 w-full lg:w-1/2 rounded-l bge-input bge-input-spacing",attrs:{"type":"text","placeholder":"Search contacts by name, addresses or people"},domProps:{"value":(_vm.searchTerm)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchContacts.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.searchTerm=$event.target.value}}}),_c('button',{staticClass:"rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out",on:{"click":_vm.searchContacts}},[_c('svg',{staticClass:"stroke-current h-5 w-5",attrs:{"fill":"none","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round","viewBox":"0 0 24 24"}},[_c('circle',{attrs:{"cx":"11","cy":"11","r":"8"}}),_c('path',{attrs:{"d":"M21 21l-4.35-4.35"}})])])])]),(_vm.viewState == 'Idle')?_c('div',[(_vm.currentTab == 'all')?_c('div',[_c('vue-good-table',{staticClass:"mt-5",attrs:{"styleClass":"vgt-table condensed","mode":"remote","rows":_vm.contacts,"columns":_vm.columns,"totalRows":_vm.totalRecords,"pagination-options":{
              enabled: true,
              perPage: _vm.pagingParams.limit,
              perPageDropdown: [1, 2, 3, 10, 20, 50, 100],
              dropdownAllowAll: false,
              setCurrentPage: _vm.pagingParams.page,
            }},on:{"on-row-click":_vm.onContactsRowSelected,"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange}})],1):_vm._e(),(_vm.currentTab == 'search')?_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchState == 'Idle'),expression:"searchState == 'Idle'"}]},[(_vm.searchTermWas && _vm.totalSearchResults > 0)?_c('h4',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.totalSearchResults)+" search results for '"+_vm._s(_vm.searchTermWas)+"'")]):_vm._e(),_c('vue-good-table',{staticClass:"mt-5",attrs:{"mode":"remote","styleClass":"vgt-table condensed","rows":_vm.searchResults,"columns":_vm.columns,"totalRows":_vm.totalSearchResults,"row-style-class":_vm.markRowAsSelected,"pagination-options":{
                enabled: true,
                perPage: _vm.searchPagingParams.limit,
                perPageDropdown: [1, 2, 3, 10, 20, 50, 100],
                dropdownAllowAll: false,
                setCurrentPage: _vm.searchPagingParams.page,
              }},on:{"on-row-click":_vm.onContactsRowSelected,"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange}}),(_vm.searchTermWas && _vm.searchResults.length == 0)?_c('h5',{staticClass:"text-center py-3"},[_vm._v("No results for "+_vm._s(_vm.searchTermWas))]):_vm._e()],1),(_vm.searchState == 'Searching')?_c('div',{staticClass:"flex items-center justify-center py-20"},[_c('Spinner',{attrs:{"color":'text-black',"size":10}})],1):_vm._e(),(_vm.searchState == 'Error')?_c('div',[_c('div',{staticClass:"mt-5 p-6 rounded bg-red-300"},[_vm._v(" Something went wrong performing your search, please try again. If the problem persists, please contact your support team. ")])]):_vm._e()]):_vm._e()]):_vm._e(),(_vm.viewState == 'Loading')?_c('div',{staticClass:"flex items-center justify-center py-20"},[_c('spinner',{attrs:{"color":'text-black',"size":10}})],1):_vm._e()])]),_c('portal',{attrs:{"to":"overlay-outlet"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }